import React, {useState} from 'react'
import {shallowEqual, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const CustomerPackageThree = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState('');

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [packageProduct, setPackageProduct] = useState('');

  return (
    <>
      <div style={{ backgroundColor: '#333', height: '100%'}}>
        <div
          style={{
            width: WIDTH,
            height: '100%',
            margin: 'auto',
            backgroundColor: '#fff'
          }}
        >
          <div
            style={{
              backgroundColor: '#f2f2f2',
              borderRadius: '15px',
              padding: '20px'
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                border: '2px solid #333',
                borderRadius: '20px',
                margin: '20px',
                padding: '10px',
                textAlign: 'center'
              }}
            >
            <span
              style={{
                color: '#000',
                fontSize: '18px',
                marginLeft: '10px',
                fontFamily: 'Linotte-Heavy',
                fontWeight: 'bold'
              }}
            >
              MUA GÓI 3
              <br/>
              BÁO CÁO VÀ KHÓA HỌC ĐẦY ĐỦ
          </span>
            </div>

            <div
              style={{
                // backgroundColor: '#000'
              }}
            >
              {/*  <div*/}
              {/*    style={{*/}
              {/*      width: '25%',*/}
              {/*      // backgroundColor: 'red',*/}
              {/*      display: 'inline-block'*/}
              {/*    }}*/}
              {/*  >*/}
              {/*<span*/}
              {/*  style={{*/}
              {/*    color: '#333',*/}
              {/*    fontSize: '26px',*/}
              {/*    lineHeight: 1.2,*/}
              {/*    fontFamily: 'Linotte-Regular',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  SƠ LƯỢC*/}
              {/*</span>*/}
              {/*  </div>*/}
              <div
                style={{
                  width: '100%',
                  // backgroundColor: 'green',
                  display: 'inline-block'
                }}
              >

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                  <p
                    style={{
                      color: '#000',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular',
                      textAlign: 'center'
                    }}
                  >
                    Để nhận bản báo cáo đầy đủ 12 thông tin chi tiết về tính cách và các khóa học online tặng kèm, mời bạn vui lòng chuyển khoản 1.600.000 đ đến tài khoản. Bạn vui lòng nhập chính xác các thông tin dưới đây để hệ thống nhận diện và mở khóa báo cáo.
                  </p>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Ngân hàng: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                Ngân hàng Seabank
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tài khoản: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                0888111266
                              </span>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '-10px' }}
                          onClick={() => navigator.clipboard.writeText('0888111266')}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                <span
                  style={{
                    color: '#333',
                    fontSize: '20px',
                    fontFamily: 'Linotte-Regular'
                  }}
                >
                  {'Tên tài khoản: '}
                </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                  Nguyễn Tiến Thành
                </span>
                </div>


                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tiền: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                1.600.000 đ
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Nội dung: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Heavy'
                    }}
                  >
                                  {'Anbi ' + currentUser.user.phone}
                                </span>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '-10px' }}
                          onClick={() => navigator.clipboard.writeText('Anbi ' + currentUser.user.phone)}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    margin: '5px',
                    padding: '10px 10px 10px 0px',
                    textAlign: 'center'
                  }}
                >
                <span
                  style={{
                    color: '#333',
                    fontSize: '22px',
                    fontFamily: 'Linotte-Regular'
                  }}
                >
                  {'Mã QR: '}
                </span>
                  <br/>
                  <div className='text-center'>
                    <img style={{ width: '90%', borderRadius: '5px', marginTop: '10px' }} src={toAbsoluteUrl('/media/logos/qr-thanh.jpg')} alt=""/>
                  </div>
                </div>

                <p
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    fontFamily: 'Linotte-Regular',
                    textAlign: 'center'
                  }}
                >
                  Nếu bạn đã hoàn thành chuyển khoản mà sau 5 phút vẫn chưa xuất hiện bản mô tả tính cách thì xin vui lòng liên hệ hotline 0967886248 để được hỗ trợ kỹ thuật.
                </p>

                {errorMessage &&
                <p
                  style={{
                    color: '#e22929',
                    fontSize: '22px',
                    fontFamily: 'Linotte-Regular',
                    textAlign: 'justify'
                  }}
                >
                  {errorMessage}
                </p>
                }

                <button
                  type="button"
                  style={{
                    width: '100%',
                    padding: '15px',
                    backgroundColor: '#30a3fc',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    borderWidth: '0px',
                    fontFamily: 'Linotte-Regular',
                    marginTop: '25px'
                  }}
                  onClick={() => {
                    setErrorMessage('')
                    axios.post(`${BACKEND_URL}/confirm-donate`, {
                      phone: currentUser.user.phone,
                    }).then(res => res.data)
                      .then(resData => {
                        let userLevel = resData.data.user.level ?? 0
                        if (userLevel == 3) {
                          window.location.replace(window.location.origin);
                        } else {
                          setErrorMessage('Hiện tại hệ thống chưa nhận được số tiền chuyển khoản của bạn. Bạn vui lòng chuyển khoản nếu chưa kịp thực hiện. Nếu bạn đã hoàn thành chuyển khoản mà sau 5 phút vẫn chưa xuất hiện bản mô tả tính cách thì xin vui lòng vuốt dọc màn hình để làm mới nội dung, hoặc liên hệ hotline 0967886248 để được hỗ trợ kỹ thuật.')
                          window.scrollTo(0, document.body.scrollHeight)
                        }
                      })
                      .catch(err => {
                        setErrorMessage('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                      })
                  }}
                >
            <span
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#fff',
                letterSpacing: 1.1
              }}
            >
              XÁC NHẬN ĐÃ CHUYỂN KHOẢN
            </span>
                </button>



              </div>
            </div>
          </div>



          <MenuTwo active='study'/>
        </div>

      </div>
    </>
  )

}

export {CustomerPackageThree}
