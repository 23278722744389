/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const ManualTransfer = () => {
  const {id} = useParams()
  const intl = useIntl()
  const [provinces, setProvinces] = useState([])
  const [newAccountQuantity, setNewAccountQuantity] = useState(0)
  const [shareholderAccountQuantities, setShareholderAccountQuantities] = useState([])
  const [province, setProvince] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])
  const [bankTransfers, setBankTransfers] = useState([])
  const [name, setName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const [value, setValue] = useState(50000)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()
  const customerValues = [
    {
      value: 50000,
      name: 'Gói 50k'
    },
    {
      value: 200000,
      name: 'Gói 200k'
    }
  ]

  useEffect(() => {
    axios.post(`${BACKEND_URL}/bank-transfer`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setBankTransfers(resData.data.bankTransfer.reverse())
        }
      })
  }, [])

  const handleSave = () => {
    try {
      setIsLoading(true)
      axios.post(`${BACKEND_URL}/customer/make-transfer`, {
        access_token: accessToken,
        value: value,
        phone: phone,
      }).then(data => data.data)
        .then(resData => {
          setIsLoading(false)
          if (resData.error_code === 1) {
            toast("Tạo lệnh chuyển tiền thủ công cho người dùng thành công", {
              onClose: () => {
                history.push('/dashboard')
              }
            })
          } else {
            setErrMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(err => {
          setIsLoading(false)
          setErrMsg('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    }
    catch (err) {
      setIsLoading(false)
      toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên")
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Tạo lệnh chuyển tiền thủ công theo số điện thoại
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group mt-5">
              <label>Số điện thoại</label>
              <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label htmlFor="pickProvince">Chọn số tiền cần tạo <span className="text-danger">*</span></label>
              <select className="form-control" id="pickProvince" value={value} onChange={(e) => setValue(e.target.value)}>
                {customerValues.map(customerValue =>
                  <option key={customerValue.value} value={customerValue.value}>{customerValue.name}</option>
                )}
              </select>
            </div>
            <div className="form-group mt-10">
              <p className="text-danger">{errMsg ?? ''}</p>
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2" disabled={isLoading} onClick={() => {handleSave()}}>Lưu</button>
            <Link to={'/dashboard'}>
              <button type="button" className="btn btn-secondary mx-2">Quay về</button>
            </Link>
          </div>
        </form>
      </div>

      <div className='card card-custom mt-10'>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Danh sách lệnh chuyển tiền</span>
                </h3>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-50px'>ID</th>
                      <th className='min-w-50px'>Ngân hàng</th>
                      <th className='min-w-50px'>Số điện thoại</th>
                      <th className='min-w-50px'>Giá trị</th>
                      <th className='min-w-50px'>Ngày tạo</th>
                      <th className='min-w-50px'>Nội dung</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {bankTransfers.map((bankTransfer) => {
                      return (
                        <tr key={bankTransfer.id}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {bankTransfer.id ?? ''}
                                  </span>
                              </div>
                            </div>
                          </td>
                          <td>
                              <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {bankTransfer.bank ?? ''}
                              </span>
                          </td>
                          <td>
                              <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {bankTransfer.phone ?? ''}
                              </span>
                          </td>
                          <td>
                              <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {bankTransfer.value ?? ''}
                              </span>
                          </td>
                          <td>
                              <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {bankTransfer.created_date &&
                                <>
                                  {bankTransfer.created_date.toString().substr(6,2) + '/' + bankTransfer.created_date.toString().substr(4,2) + '/' + bankTransfer.created_date.toString().substr(0,4) ?? ''}
                                </>
                                }
                              </span>
                          </td>
                          <td>
                              <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {bankTransfer.content ?? ''}
                              </span>
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ManualTransfer}
