/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const Ancare = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [age, setAge] = useState('TRE_TRUNG')
  const [life, setLife] = useState('LANH_MANH')
  const [general, setGeneral] = useState('TRAN_DAY')
  const [sick, setSick] = useState([])
  const [users, setUsers] = useState([])
  const [khoeManh, setKhoeManh] = useState([])
  const [nguyCo, setNguyCo] = useState([])
  const [vanDe, setVanDe] = useState([])
  const [chuaNhanXet, setChuaNhanXet] = useState([])
  const [nhomBenh, setNhomBenh] = useState([])
  const [loiKhuyen, setLoiKhuyen] = useState([])

  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)

  useEffect(() => {
    axios.post(`${BACKEND_URL}/ancare-list`, {
      access_token: accessToken,
    }).then(res => res.data)
      .then(resData => {
        setIsLoading(false)
        if (resData.error_code === 1) {
          setUsers(resData.data.ancare)
        } else {
          setValidateErrorMsgArr([resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên'])
        }
      })
  }, [])

  const handleConfirm = () => {
    setIsLoading(true)
    setValidateErrorMsgArr([])
    axios.post(`${BACKEND_URL}/ancare`, {
      access_token: accessToken,
      name: name,
      phone: phone,
      // age: age,
      // life: life,
      // general: general,
      // sick: sick.toString(),
      khoe_manh: khoeManh.toString(),
      nguy_co: nguyCo.toString(),
      van_de: vanDe.toString(),
      chua_nhan_xet: chuaNhanXet.toString(),
      nhom_benh: nhomBenh.toString(),
      loi_khuyen: loiKhuyen.toString(),
    }).then(res => res.data)
      .then(resData => {
        setIsLoading(false)
        if (resData.error_code === 1) {
          toast("Tạo tài khoản ancare thành công", {
            onClose: () => {
              window.location.replace('/ancare')
            }
          })
        } else {
          setValidateErrorMsgArr([resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên'])
        }
      })
  }

  return (
    <>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Ancare
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Họ và tên <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>Số điện thoại <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>

            <div className="form-group mt-5 d-none">
              <label>Độ tuổi <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={age}
                  onChange={(e) => {setAge(e.target.value)}}
                  className="form-control"
                >
                  <option value='TRE_TRUNG'>TRẺ TRUNG</option>
                  <option value='TRUNG_NIEN'>TRUNG NIÊN</option>
                  <option value='KHONG_CON_TRE'>KHÔNG CÒN TRẺ</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5 d-none">
              <label>Cách sống <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={life}
                  onChange={(e) => {setLife(e.target.value)}}
                  className="form-control"
                >
                  <option value='LANH_MANH'>LÀNH MẠNH, TÍCH CỰC</option>
                  <option value='CONG_VIEC'>QUÁ TẬP TRUNG CÔNG VIỆC</option>
                  <option value='NUONG_CHIEU'>NUÔNG CHIỀU BẢN THÂN</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5 d-none">
              <label>Tình trạng chung <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={general}
                  onChange={(e) => {setGeneral(e.target.value)}}
                  className="form-control"
                >
                  <option value='TRAN_DAY'>TRÀN ĐẦY SỨC SỐNG</option>
                  <option value='VAN_TRAN_DAY'>VẪN TRÀN ĐẦY SỨC SỐNG</option>
                  <option value='CANG_THANG'>CĂNG THẲNG LO LẮNG</option>
                  <option value='LO_LANG'>CĂNG THẲNG LO LẮNG</option>
                  <option value='CAI_THIEN'>CẢI THIỆN SỨC KHỎE NGAY</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5 d-none">
              <label>Nguy cơ bệnh <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={sick}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setSick(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '450px' }}
                >
                  <option value='TIM_MACH' className='mb-3'>VẤN ĐỀ TIM MẠCH</option>
                  <option value='HO_HAP' className='mb-3'>VẤN ĐỀ HÔ HẤP</option>
                  <option value='TIEU_HOA' className='mb-3'>VẤN ĐỀ TIÊU HÓA</option>
                  <option value='CHUYEN_HOA' className='mb-3'>VẤN ĐỀ CHUYỂN HÓA</option>
                  <option value='TIET_NIEU' className='mb-3'>VẤN ĐỀ TIẾT NIỆU</option>
                  <option value='CO_XUONG_KHOP' className='mb-3'>VẤN ĐỀ CƠ XƯƠNG KHỚP</option>
                  <option value='DA_LIEU' className='mb-3'>VẤN ĐỀ DA LIỄU</option>
                  <option value='THAN_KINH' className='mb-3'>VẤN ĐỀ HỆ THẦN KINH</option>
                  <option value='THI_LUC' className='mb-3'>VẤN ĐỀ THỊ LỰC</option>
                  <option value='THINH_LUC' className='mb-3'>VẤN ĐỀ THÍNH LỰC</option>
                  <option value='MIEN_DICH' className='mb-3'>VẤN ĐỀ HỆ MIỄN DỊCH</option>
                  <option value='LAO_HOA' className='mb-3'>VẤN ĐỀ LÃO HÓA</option>
                  <option value='CANG_THANG' className='mb-3'>VẤN ĐỀ CĂNG THẲNG</option>
                  <option value='LUOI_VAN_DONG' className='mb-3'>VẤN ĐỀ LƯỜI VẬN ĐỘNG</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Khỏe mạnh <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={khoeManh}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setKhoeManh(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '350px' }}
                >
                  <option value='TUAN_HOAN' className='mb-3'>Tuần hoàn</option>
                  <option value='HO_HAP' className='mb-3'>Hô hấp</option>
                  <option value='TIEU_HOA' className='mb-3'>Tiêu hóa</option>
                  <option value='TIET_NIEU' className='mb-3'>Tiết niệu</option>
                  <option value='NOI_TIET' className='mb-3'>Nội tiết</option>
                  <option value='CO_XUONG_KHOP' className='mb-3'>Cơ xương khớp</option>
                  <option value='THAN_KINH' className='mb-3'>Thần kinh</option>
                  <option value='ROI_LOAN_CHUYEN_HOA' className='mb-3'>Rối loạn chuyển hóa</option>
                  <option value='DA_LIEU' className='mb-3'>Da liễu</option>
                  <option value='MAT' className='mb-3'>Mắt</option>
                  <option value='TAI_MUI_HONG' className='mb-3'>Tai mũi họng</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Nguy cơ <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={nguyCo}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setNguyCo(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '350px' }}
                >
                  <option value='TUAN_HOAN' className='mb-3'>Tuần hoàn</option>
                  <option value='HO_HAP' className='mb-3'>Hô hấp</option>
                  <option value='TIEU_HOA' className='mb-3'>Tiêu hóa</option>
                  <option value='TIET_NIEU' className='mb-3'>Tiết niệu</option>
                  <option value='NOI_TIET' className='mb-3'>Nội tiết</option>
                  <option value='CO_XUONG_KHOP' className='mb-3'>Cơ xương khớp</option>
                  <option value='THAN_KINH' className='mb-3'>Thần kinh</option>
                  <option value='ROI_LOAN_CHUYEN_HOA' className='mb-3'>Rối loạn chuyển hóa</option>
                  <option value='DA_LIEU' className='mb-3'>Da liễu</option>
                  <option value='MAT' className='mb-3'>Mắt</option>
                  <option value='TAI_MUI_HONG' className='mb-3'>Tai mũi họng</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Đang có vấn đề <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={vanDe}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setVanDe(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '350px' }}
                >
                  <option value='TUAN_HOAN' className='mb-3'>Tuần hoàn</option>
                  <option value='HO_HAP' className='mb-3'>Hô hấp</option>
                  <option value='TIEU_HOA' className='mb-3'>Tiêu hóa</option>
                  <option value='TIET_NIEU' className='mb-3'>Tiết niệu</option>
                  <option value='NOI_TIET' className='mb-3'>Nội tiết</option>
                  <option value='CO_XUONG_KHOP' className='mb-3'>Cơ xương khớp</option>
                  <option value='THAN_KINH' className='mb-3'>Thần kinh</option>
                  <option value='ROI_LOAN_CHUYEN_HOA' className='mb-3'>Rối loạn chuyển hóa</option>
                  <option value='DA_LIEU' className='mb-3'>Da liễu</option>
                  <option value='MAT' className='mb-3'>Mắt</option>
                  <option value='TAI_MUI_HONG' className='mb-3'>Tai mũi họng</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Chưa có nhận xét <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={chuaNhanXet}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setChuaNhanXet(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '350px' }}
                >
                  <option value='TUAN_HOAN' className='mb-3'>Tuần hoàn</option>
                  <option value='HO_HAP' className='mb-3'>Hô hấp</option>
                  <option value='TIEU_HOA' className='mb-3'>Tiêu hóa</option>
                  <option value='TIET_NIEU' className='mb-3'>Tiết niệu</option>
                  <option value='NOI_TIET' className='mb-3'>Nội tiết</option>
                  <option value='CO_XUONG_KHOP' className='mb-3'>Cơ xương khớp</option>
                  <option value='THAN_KINH' className='mb-3'>Thần kinh</option>
                  <option value='ROI_LOAN_CHUYEN_HOA' className='mb-3'>Rối loạn chuyển hóa</option>
                  <option value='DA_LIEU' className='mb-3'>Da liễu</option>
                  <option value='MAT' className='mb-3'>Mắt</option>
                  <option value='TAI_MUI_HONG' className='mb-3'>Tai mũi họng</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Nhóm bệnh <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={nhomBenh}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setNhomBenh(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '1250px' }}
                >
                  <option value='CAO_HUYET_AP' className='mb-3'>Cao huyết áp</option>
                  <option value='ROI_LOAN_NHIP_TIM' className='mb-3'>Rối loạn nhịp tim</option>
                  <option value='MO_MAU_CAO' className='mb-3'>Mỡ máu cao</option>
                  <option value='XO_VUA_DONG_MACH' className='mb-3'>Xơ vữa động mạch</option>
                  <option value='HEN_XUYEN' className='mb-3'>Hen xuyễn</option>
                  <option value='VIEM_PHOI' className='mb-3'>Viêm phổi</option>
                  <option value='PHOI_TAC_NGHEN' className='mb-3'>Phổi tắc nghẽn</option>
                  <option value='VIEM_LOET_DA_DAY' className='mb-3'>Viêm loét dạ dày</option>
                  <option value='TRAO_NGUOC_DA_DAY' className='mb-3'>Trào ngược dạ dày</option>
                  <option value='VIEM_DAI_TRANG' className='mb-3'>Viêm đại tràng</option>
                  <option value='SOI_MAT' className='mb-3'>Sỏi mật</option>
                  <option value='MEN_GAN_CAO' className='mb-3'>Men gan cao</option>
                  <option value='SUY_GAN' className='mb-3'>Suy gan</option>
                  <option value='NHIEM_TRUNG_TIET_NIEU' className='mb-3'>Nhiễm trùng tiết niệu</option>
                  <option value='SOI_TIET_NIEU' className='mb-3'>Sỏi tiết niệu</option>
                  <option value='SUY_THAN' className='mb-3'>Suy thận</option>
                  <option value='SOI_THAN' className='mb-3'>Sỏi thận</option>
                  <option value='TIEU_DUONG' className='mb-3'>Tiểu đường</option>
                  <option value='ROI_LOAN_TUYEN_GIAP' className='mb-3'>Rối loạn tuyến giáp</option>
                  <option value='ROI_LOAN_HORMONE' className='mb-3'>Rối loạn hormone</option>
                  <option value='THOAI_HOA_COT_SONG' className='mb-3'>Thoái hóa cột sống</option>
                  <option value='LOANG_XUONG' className='mb-3'>Loãng xương</option>
                  <option value='VIEM_KHOP' className='mb-3'>Viêm khớp</option>
                  <option value='ROI_LOAN_LO_AU' className='mb-3'>Rối loạn lo âu</option>
                  <option value='ROI_LOAN_GIAC_NGU' className='mb-3'>Rối loạn giấc ngủ</option>
                  <option value='NGUY_CO_SA_SUT' className='mb-3'>Nguy cơ sa sút trí tuệ</option>
                  <option value='BEO_PHI' className='mb-3'>Béo phì</option>
                  <option value='THIEU_MAU' className='mb-3'>Thiếu máu</option>
                  <option value='MAT_CAN_BANG' className='mb-3'>Mất cân bằng</option>
                  <option value='VIEM_DA' className='mb-3'>Viêm da</option>
                  <option value='NAM_DA' className='mb-3'>Nấm da</option>
                  <option value='MUN_TRUNG_CA' className='mb-3'>Mụn trứng cá</option>
                  <option value='DI_UNG_DA' className='mb-3'>Dị ứng da</option>
                  <option value='CAN_LOAN_VIEN' className='mb-3'>Cận loạn viễn</option>
                  <option value='DUC_THUY_TINH_THE' className='mb-3'>Đục thủy tinh thể</option>
                  <option value='VIEM_KET_MAC' className='mb-3'>Viêm kết mạc</option>
                  <option value='MAT_KHA_NANG' className='mb-3'>Mất khả năng nhìn</option>
                  <option value='VIEM_TAI_NGOAI' className='mb-3'>Viêm tai ngoài</option>
                  <option value='NGHE_KEM' className='mb-3'>Nghe kém</option>
                  <option value='VIEM_XOANG' className='mb-3'>Viêm xoang</option>
                  <option value='VIEM_MUI' className='mb-3'>Viêm mũi</option>
                  <option value='VIEM_HONG' className='mb-3'>Viêm họng</option>
                  <option value='VIEM_AMIDAN' className='mb-3'>Viêm amidan</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Lời khuyên <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={loiKhuyen}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setLoiKhuyen(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '250px' }}
                >
                  <option value='SUC_KHOE_BINH_THUONG' className='mb-3'>Sức khỏe bình thường</option>
                  <option value='PHAT_HIEN_ROI_LOAN' className='mb-3'>Phát hiện rối loạn</option>
                  <option value='PHAT_HIEN_BENH_LY' className='mb-3'>Phát hiện bệnh lý</option>
                  <option value='DAU_HIEU_BAT_THUONG' className='mb-3'>Có dấu hiệu bất thường</option>
                  <option value='PHAT_HIEN_BENH_MAN_TINH' className='mb-3'>Phát hiện bệnh mãn tính</option>
                  <option value='PHAT_HIEN_BENH_LY' className='mb-3'>Phát hiện bệnh lý</option>
                  <option value='NGUY_CO_LOI_SONG' className='mb-3'>Nguy cơ lối sống không lành mạnh</option>
                  <option value='PHAT_HIEN_CANG_THANG' className='mb-3'>Phát hiện căng thẳng lo âu</option>

                </select>
              </div>
            </div>

            <div className="form-group mt-10">
              {validateErrorMsgArr.map((validateErrorMsg, index) => <p className="text-danger" key={index}>{validateErrorMsg}</p>)}
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2 " disabled={isLoading} onClick={() => handleConfirm()}>Xác nhận</button>
            <button type="reset" className="btn btn-secondary mx-2">Hủy bỏ</button>
          </div>
        </form>
        <div className='card-body'>
          <table className="table table-bordered table-responsive">
            <thead>
            <tr>
              <th>Tên</th>
              <th>SĐT</th>
              <th>Code</th>
              <th>Is_scan</th>
              {/*<th>Age</th>*/}
              {/*<th>Life</th>*/}
              {/*<th>General</th>*/}
              {/*<th>Sick</th>*/}
              <th>khoe_manh</th>
              <th>nguy_co</th>
              <th>van_de</th>
              <th>chua_nhan_xet</th>
              <th>nhom_benh</th>
              <th>loi_khuyen</th>
            </tr>
            </thead>
            <tbody>
            {users.length > 0 && users.map((user) =>
              <tr key={user.code}>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.code}</td>
                <td>{user.is_scan == 1 ? 'Đã quét' : 'Chưa quét'}</td>
                {/*<td>{user.age}</td>*/}
                {/*<td>{user.life}</td>*/}
                {/*<td>{user.general}</td>*/}
                {/*<td>{user.sick}</td>*/}
                <td>{user.khoe_manh}</td>
                <td>{user.nguy_co}</td>
                <td>{user.van_de}</td>
                <td>{user.chua_nhan_xet}</td>
                <td>{user.nhom_benh}</td>
                <td>{user.loi_khuyen}</td>
              </tr>
            )}


            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export {Ancare}
