/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const SaleUpdateNote = () => {
  const {id} = useParams()
  const intl = useIntl()
  const [provinces, setProvinces] = useState([])
  const [newAccountQuantity, setNewAccountQuantity] = useState(0)
  const [shareholderAccountQuantities, setShareholderAccountQuantities] = useState([])
  const [province, setProvince] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])
  const [name, setName] = useState('')
  const [note, setNote] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const [auto, setAuto] = useState(0)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  const handleSave = () => {
    try {
      setIsLoading(true)
      axios.post(`${BACKEND_URL}/sale/update-note`, {
        access_token: accessToken,
        note: note,
      }).then(data => data.data)
        .then(resData => {
          setIsLoading(false)
          if (resData.error_code === 1) {
            toast("Cập nhật số điện thoại liên hệ thành công", {
              onClose: () => {
                window.location.replace('/dashboard')
              }
            })
          } else {
            setErrMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(err => {
          setIsLoading(false)
          setErrMsg('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    }
    catch (err) {
      setIsLoading(false)
      toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên")
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Cập nhật số điện thoại liên hệ
          </h3>
        </div>
        <form>
          <div className="card-body">
            <h4>Số điện thoại liên hệ hiện tại: <span className='text-danger'>{ currentUser.user.note ?? 'Chưa có' }</span> </h4>
            <div className="form-group">
              <label>Số điện thoại liên hệ mới:<span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Nhập Số điện thoại liên hệ" value={note} onChange={(e) => setNote(e.target.value)}/>
            </div>
            <div className="form-group mt-10">
              <p className="text-danger">{errMsg ?? ''}</p>
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2" disabled={isLoading} onClick={() => {handleSave()}}>Lưu</button>
            <Link to={'/dashboard'}>
              <button type="button" className="btn btn-secondary mx-2">Quay về</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export {SaleUpdateNote}
