/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {CustomerReport} from "../pages/CustomerReport";
import {CustomerStudy} from "../pages/CustomerStudy";
import {CustomerStudyThink} from "../pages/CustomerStudyThink";
import {CustomerStudyEnglish} from "../pages/CustomerStudyEnglish";
import {CustomerListStudy} from "../pages/CustomerListStudy";
import {CustomerPackage} from "../pages/CustomerPackage";
import {CustomerPackageOne} from "../pages/CustomerPackageOne";
import {CustomerPackageTwo} from "../pages/CustomerPackageTwo";
import {CustomerPackageThree} from "../pages/CustomerPackageThree";
import {CustomerStudyDes} from "../pages/CustomerStudyDes";
import {CustomerIntro} from "../pages/CustomerIntro";
import {CustomerInfo} from "../pages/CustomerInfo";
import {CustomerInfoChangePassword} from "../pages/CustomerInfoChangePassword";
import {CustomerInfoChangeName} from "../pages/CustomerInfoChangeName";
import {UpgradeGlobal} from "../pages/UpgradeGlobal";
import {CustomerReportVideo} from "../pages/CustomerReportVideo";
import {CustomerReportImage} from "../pages/CustomerReportImage";
import {Map} from "../pages/Map";
import {Health} from "../pages/Health";
import {UpdateFamilyPhone} from "../pages/UpdateFamilyPhone";
import {CustomerDaily} from "../pages/CustomerDaily";
import {CustomerScan} from "../pages/CustomerScan";

const Routes: FC = () => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  // @ts-ignore
  const userInfo = useSelector(store => store.userInfo)
  // const isFirstLogin = userInfo.isFirstLogin
  const isFirstLogin = false
  console.log({isFirstLogin})

  if (isAuthorized && isAuthorized.user.auth_role_id === 4) {
    return (
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            <Switch>
              <Route path='/customer/report' component={CustomerReport} />
              <Route path='/customer/report-video' component={CustomerReportVideo} />
              <Route path='/customer/report-image' component={CustomerReportImage} />
              <Route path='/customer/daily' component={CustomerDaily} />
              <Route path='/customer/scan' component={CustomerScan} />
              <Route path='/customer/map' component={Map} />
              <Route path='/customer/health' component={Health} />
              <Route path='/customer/study' component={CustomerStudy} />
              <Route path='/customer/study-think' component={CustomerStudyThink} />
              <Route path='/customer/study-english' component={CustomerStudyEnglish} />
              <Route path='/customer/list-study' component={CustomerListStudy} />
              <Route path='/customer/package' component={CustomerPackage} />
              <Route path='/customer/package-one' component={CustomerPackageOne} />
              <Route path='/customer/package-two' component={CustomerPackageTwo} />
              <Route path='/customer/package-three' component={CustomerPackageThree} />
              <Route path='/customer/study-des' component={CustomerStudyDes} />
              <Route path='/customer/intro' component={CustomerIntro} />
              <Route path='/customer/info' component={CustomerInfo} />
              <Route path='/customer/info-change-password' component={CustomerInfoChangePassword} />
              <Route path='/customer/info-change-name' component={CustomerInfoChangeName} />
              <Route path='/customer/upgrade-global' component={UpgradeGlobal} />
              <Route path='/customer/update-family-phone' component={UpdateFamilyPhone} />
              {!isFirstLogin &&
                <Redirect to='/customer/daily' />
              }
              {isFirstLogin &&
                <Redirect to='/customer/update-family-phone' />
              }
            </Switch>
          </>
        )}
      </Switch>
    )
  }

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
