import React, {useState, useRef, useEffect} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {Link, useHistory} from "react-router-dom";
import * as faceapi from "face-api.js";

const CustomerScan = () => {

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch()
  const history = useHistory()

  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [hasError, setHasError] = useState(false)
  const [agencyPhone, setAgencyPhone] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const userInfo = useSelector(state => state.userInfo)
  console.log({userInfo})
  const LANGUAGE = userInfo.language
  const [hasFace, setHasFace] = useState(false);
  const [pressAnalysis, setPressAnalysis] = useState(false);
  const [scanCode, setScanCode] = useState('');
  const videoScan = useRef()

  const [count, setCount] = useState(0);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isModelLoaded, setIsModelLoaded] = useState(false);

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
      // await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
      // await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
      // await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
      setIsModelLoaded(true);
    };
    loadModels();
  }, []);

  useEffect(() => {
    if (isModelLoaded) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          videoRef.current.srcObject = stream;
        })
        .catch((err) => console.error("Error accessing webcam: ", err));
    }
  }, [isModelLoaded]);

  const handleVideoPlay = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    const displaySize = { width: video.width, height: video.height };
    faceapi.matchDimensions(canvas, displaySize);

    setInterval(async () => {
      const detections = await faceapi
        .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions({
          scoreThreshold: 0.95
        }))
      // .withFaceLandmarks()
      // .withFaceExpressions();
      if (detections.length > 0) {
        setCount(prevCount => {
          if (prevCount === 10) {
            setPressAnalysis(true)
            videoScan.current.play()
            axios.post('https://backend.robotanan.com/api/customer/daily-scan', {
              access_token: currentUser.user.access_token,
            }).then(data => data)
              .then((resData) => {
                console.log({resData})
                setPressAnalysis(true)
                videoScan.current.play()
                if (resData.data.error_code !== 1) {
                  setHasError(true)
                  setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                  // setTimeout(() => window.location.replace('/'), 3000)
                }
              })
              .catch(err => {
                console.log(err)
                setHasError(true)
                setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
              })
          }
          return prevCount + 1
        }); // Luôn sử dụng giá trị mới nhất của count
      }

      // const resizedDetections = faceapi.resizeResults(detections, displaySize);

      // canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      // faceapi.draw.drawDetections(canvas, resizedDetections);
      // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
      // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
    }, 1000);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div style={{ backgroundColor: '#000', height: '100%'}}>
        <div
          style={{
            width: WIDTH,
            height: '100%',
            margin: 'auto',
            backgroundColor: '#000',
          }}
        >



          <div
            style={{
              width: '100%',
              padding: '0px'
            }}
          >
            {(hasFace && !hasError && !pressAnalysis) && <>
              <button
                type="button"
                style={{
                  width: '100%',
                  padding: '25px',
                  backgroundColor: (hasFace && !hasError) ? '#30a3fc' : '#a1e6b9',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  borderWidth: '0px'
                }}
                onClick={() => {
                  axios.post('https://backend.robotanan.com/api/customer/daily-scan', {
                    access_token: currentUser.user.access_token,
                  }).then(data => data)
                    .then((resData) => {
                      console.log({resData})
                      setPressAnalysis(true)
                      videoScan.current.play()
                      if (resData.data.error_code !== 1) {
                        setHasError(true)
                        setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                        // setTimeout(() => window.location.replace('/'), 3000)
                      }
                    })
                    .catch(err => {
                      console.log(err)
                      setHasError(true)
                      setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                    })
                }}
              >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA',
                marginTop: '5px',
                marginBottom: '5px'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'BẮT ĐẦU PHÂN TÍCH'
                :
                'START ANALYSIS'
              }
            </span>
              </button>
            </>}

            {count <= 10 &&
            <button
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                // borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              <span
                style={{
                  fontSize: '22px',
                  fontFamily: 'UTM-APTIMABOLD',
                  color: '#ffffff',
                  letterSpacing: 2.0
                }}
              >
                  Vui lòng giữ toàn bộ khuôn mặt trong khung hình
                  <br/>
                {LANGUAGE === 'VI' &&
                <span>Tiến độ: {count} / 10</span>
                }
                {LANGUAGE === 'EN' &&
                <span>Process: {count} / 10</span>
                }
              </span>
            </button>
            }

            {isModelLoaded &&
            <div
              style={{
                // borderRadius: '20px',
                display: pressAnalysis ? 'none' : 'block'
              }}
            >
              <video
                ref={videoRef}
                autoPlay
                muted
                onPlay={handleVideoPlay}
                width={'100%'}
              />
              <canvas ref={canvasRef} />
            </div>
            }

            {/*<div>*/}
            {/*  <iframe src="https://face.robotanan.com/" style={{ width: '100%', height: '540px', display: pressAnalysis ? 'none' : 'block', overflow: 'hidden' }} allow="camera" />*/}
            {/*</div>*/}

            <video style={{display: !pressAnalysis ? 'none' : 'block' }} ref={videoScan} onEnded={() => { if (!hasError) window.location.replace('/')}} className='w-100' src={"https://backend.robotanan.com/character/scan.mp4"} />


            {errorMsg && (<h1 style={{ color: 'red', fontFamily: 'UTM-APTIMA' }} >{errorMsg}</h1>)}

          </div>




        </div>

      </div>

    </div>
  )

}

export {CustomerScan}
