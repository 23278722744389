/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import SVG from "react-inlinesvg";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import moment from 'moment'

const FullDaily = () => {
  const audioElement1 = useRef()
  const audioElement2 = useRef()
  const audioElement3 = useRef()
  const audioElement4 = useRef()
  const audioElement5 = useRef()
  const audioElement6 = useRef()
  const audioElement7 = useRef()
  const audioElement8 = useRef()
  const audioElement9 = useRef()
  const audioElement10 = useRef()
  const audioElement11 = useRef()
  const audioElement12 = useRef()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const characterId = currentUser.user.character_id;
  const minorCharacterId = currentUser.user.minor_character_id;
  const roadCharacterId = currentUser.user.road_character_id;
  const spiritLevel = currentUser.user.spirit_level;
  const isFirst = currentUser.user.is_first;
  const [showButton, setShowButton] = useState(true);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const reportVideo = useRef()
  const scanDate = currentUser.user.scan_date;
  let today = moment().format('YYYYMMDD')
  const scanToday = scanDate == today

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const history = useHistory()

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      {!scanToday &&
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundImage: `url(${toAbsoluteUrl('/media/logos/bg.jpg')})`,
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            padding: '20px',
            marginBottom: '100px'
          }}
        >
          <button
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: '#30a3fc',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderWidth: '0px',
              //display: showButton ? 'block' : 'none',
              display: 'none'
            }}
            onClick={() => {
              history.push('/customer/scan')
            }}
          >
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: 'bolder',
                  color: '#fff',
                  fontFamily: 'Linotte-Heavy',
                  marginBottom: '20px'
                }}
              >
                {LANGUAGE === 'VI' ? 'PHÂN TÍCH HÔM NAY' : 'TODAY\'S ANALYSIS'}
              </span>
          </button>

          <div className='text-center'
               onClick={() => {
                 history.push('/customer/scan')
               }}
          >
            <img className='w-50' src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/butt-vi.png' : '/media/logos/butt-en.png')} alt='img' />
          </div>

          <div className='text-center'>
            <img className='w-50' src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/touch-vi.png' : '/media/logos/touch-en.png')} alt='img' />
          </div>
        </div>

        <Link to={{
          pathname: '/customer/update-family-phone',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Cập nhật mã gia đình và sức khỏe tinh thần'
                  : 'Family and mental health code update'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-password',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi mật khẩu'
                  : 'Change Password'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-name',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi tên'
                  : 'Change Name'
                }
              </h4>
            </div>
          </div>
        </Link>

        <div style={{ padding: '10px' }}>
          <div
            onClick={() => dispatch(auth.actions.logout())}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Đăng xuất'
                : 'Log out'
              }
            </h4>
          </div>
        </div>

        {/*menu*/}
        <MenuTwo active='daily'/>
      </div>
      }

      {scanToday &&
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#333333',
          backgroundSize: "cover"
        }}
      >
        <>
          {/*video*/}
          <div
            style={{
              padding: '20px'
            }}
          >
            <video
              style={{
                borderRadius: '20px',
              }}
              controls={true}
              className='w-100' src={LANGUAGE === 'VI' ? `https://backend.robotanan.com/daily-video-vn/${currentUser.user.scan_daily_result}vn.mp4` : `https://backend.robotanan.com/daily-video-en/${currentUser.user.scan_daily_result}en.mp4`}
            />
          </div>

          <div
            style={{
              width: '100%',
              height: '60px',
              paddingTop: '30px',
              paddingLeft: '20px',
              backgroundColor: '#333',
              paddingRight: '20px',
              justifyContent: 'space-between',
              display: 'flex'
            }}
          >
            <h1 style={{
              color: 'white',
              fontFamily: 'Linotte-Heavy',
              fontSize: '24px'
            }}>
              {/**/}
            </h1>
            <div>
              <h1
                onClick={() => dispatch({
                  type: 'SET_LANGUAGE',
                  payload: {
                    language: 'VI'
                  }
                })}
                style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                VIE
              </h1>
              <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                |
              </h1>
              <h1
                onClick={() => dispatch({
                  type: 'SET_LANGUAGE',
                  payload: {
                    language: 'EN'
                  }
                })}
                style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                ENG
              </h1>
            </div>
          </div>

          <div
            style={{
              margin: '0px 0px 0px 0px',
              backgroundColor: '#fff',
            }}
          >
            <p
              className='text-center pt-7 mb-2'
              style={{
                fontFamily: 'Linotte-Bold',
                fontSize: '32px',
                color: '#ee962e',
                textTransform: 'uppercase'
              }}
            >
              {LANGUAGE == 'VI'
                ?
                'PHẨM CHẤT PHÙ HỢP'
                :
                'the right qualities'
              }
            </p>

            <p
              className='text-center pb-4 m-0'
              style={{
                fontFamily: 'Linotte-Bold',
                fontSize: '24px',
                color: '#726e6e',
                textTransform: 'uppercase'
              }}
            >
              {LANGUAGE == 'VI'
                ?
                'ĐỂ RÈN LUYỆN HÔM NAY'
                :
                'to practice today'
              }
            </p>

          </div>

          {/*ảnh full*/}
          <img style={{ width: WIDTH, height: 'auto' }}
               src={LANGUAGE === 'VI' ? `https://backend.robotanan.com/daily-vn/Viet_${currentUser.user.scan_daily_result}.jpg` : `https://backend.robotanan.com/daily-en/Eng_${currentUser.user.scan_daily_result}.jpg`} alt=""
          />

          <div
            style={{
              display: LANGUAGE === 'VI' ? 'block' : 'none',
              padding: '20px',
              textAlign: "center"
            }}
          >
            <audio ref={audioElement1} controls>
              <source src={`https://backend.robotanan.com/daily-audio-vn/${currentUser.user.scan_daily_result}.mp3`} type="audio/wav" />
            </audio>
          </div>

          <div
            style={{
              display: LANGUAGE === 'VI' ? 'none' : 'block',
              padding: '20px',
              textAlign: "center"
            }}
          >
            <audio ref={audioElement2} controls>
              <source src={`https://backend.robotanan.com/daily-audio-en/a${currentUser.user.scan_daily_result}.mp3`} type="audio/wav" />
            </audio>
          </div>


        </>


        <Link to={{
          pathname: '/customer/update-family-phone',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Cập nhật mã gia đình và sức khỏe tinh thần'
                  : 'Family and mental health code update'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-password',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi mật khẩu'
                  : 'Change Password'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-name',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi tên'
                  : 'Change Name'
                }
              </h4>
            </div>
          </div>
        </Link>

        <div style={{ padding: '10px' }}>
          <div
            onClick={() => dispatch(auth.actions.logout())}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Đăng xuất'
                : 'Log out'
              }
            </h4>
          </div>
        </div>

        {/*menu*/}
        <MenuTwo active='daily'/>
      </div>
      }

    </div>
  )
}

export {FullDaily}
